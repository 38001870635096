.ps__rail-x, .ps__rail-y, .ps:hover > .ps__rail-x, .ps:hover > .ps__rail-y{
	opacity: 1;
	display: block;
}


.ps--active-x > .ps__rail-x, .ps--active-y > .ps__rail-y{
	background-color:#fff;

}

.ps__rail-y{
	width:11px;
}
.ps__rail-x {
	height: 11px;
}

.ps__thumb-x, .ps__thumb-x{
	background-color:#aaa;
}

.ps__rail-x:hover > .ps__thumb-x, .ps__rail-x:focus > .ps__thumb-x, .ps__rail-x.ps--clicking .ps__thumb-x{
	height: 6px;
	background-color:#aaa;
}


.ps__rail-y:hover > .ps__thumb-y, .ps__rail-y:focus > .ps__thumb-y, .ps__rail-y.ps--clicking .ps__thumb-y{
	width: 6px;
	background-color:#aaa;
}

.ps:hover > .ps__rail-x, .ps:hover > .ps__rail-y, .ps--focus > .ps__rail-x, .ps--focus > .ps__rail-y, .ps--scrolling-x > .ps__rail-x, .ps--scrolling-y > .ps__rail-y{
	opacity: 1;

}